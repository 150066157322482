import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ConfirmUserDetails from "components/ConfirmUserDetails/ConfirmUserDetails";
import Debug from "components/Debug/Debug";
import DictionaryPopover from "components/DictionaryPopover";
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import { ToolsSkeleton } from "components/article/aside/Tools";
import Paywall from "components/article/paywall/Paywall";
import { DefaultPageTemplate } from "components/article/templates/Default/DefaultPageTemplate";
import { FrontpageTemplate } from "components/article/templates/frontPage/FrontPageTemplate";
import { ProfessionalPageTemplate } from "components/article/templates/Professional/ProfessionalPageTemplate";
import { Banner } from "components/banner";
import { InteractionsProvider } from "components/interactions/InteractionsContext";
import { Sitelist } from "components/sitelist/Sitelist";
import { useGTMCustomVariables } from "hooks/useGTMCustomVariables";
import usePath from "hooks/usePath";
import { GetStaticPaths, GetStaticPropsContext } from "next";
import { ParsedUrlQuery } from "querystring";
import Skeleton from "@mui/material/Skeleton";
import { IFrontpage, IPage, getPage } from "data/contentData/api/content";
import { getRedirect } from "data/contentData/api/redirect";
import { useGetPage } from "data/contentData/hooks/content.hooks";
import { useGetUserSettings } from "data/contentData/hooks/user.hooks";
import { isPublished } from "lib/cms/is-published";
import { CONTENT_TYPE, getContentType } from "lib/get-content-type";
import { isNonPage } from "lib/string/is-non-page";
import { toPath } from "lib/string/to-path";
export const noPrintSectionTypes = ["further-reading", "illustrations", "animations", "sources"];
type PagePropsType = {
  page: IPage;
};
export default function Page({
  page: initialPage
}: PagePropsType) {
  const url = usePath();
  const page = useGetPage(initialPage);
  const {
    data: userSettings
  } = useGetUserSettings();
  useGTMCustomVariables(page);
  if (!page) {
    return <PageSkeleton page={page} />;
  }
  let PageComponent;
  switch (getContentType(page.types)) {
    case CONTENT_TYPE.START:
      PageComponent = <FrontpageTemplate frontpage={page as IFrontpage} />;
      break;
    case CONTENT_TYPE.LIST:
      PageComponent = <Sitelist page={page} />;
      break;
    case CONTENT_TYPE.ARTICLE_PUBLIC:
    case CONTENT_TYPE.ARTICLE_DEFAULT:
      PageComponent = <>
                    {userSettings?.markWordsInDictionary && <DictionaryPopover />}
                    <DefaultPageTemplate page={page} />
                    <Paywall page={page} />
                </>;
      break;
    case CONTENT_TYPE.ARTICLE_PROFESSIONAL:
      PageComponent = <>
                    {userSettings?.markWordsInDictionary && <DictionaryPopover />}
                    <ProfessionalPageTemplate page={page} />
                    <Paywall page={page} />
                </>;
      break;
  }
  const {
    hasNav,
    hasTools,
    favorite
  } = getPageInteractions(page, url);
  return <Layout data-sentry-element="Layout" data-sentry-component="Page" data-sentry-source-file="[[...page]].tsx">
            <InteractionsProvider contentId={page.contentId} hasNav={hasNav} hasTools={hasTools} favoriteData={favorite} showPreviewFAB={getContentType(page.types) !== CONTENT_TYPE.LIST} data-sentry-element="InteractionsProvider" data-sentry-source-file="[[...page]].tsx">
                <Breadcrumb page={page} data-sentry-element="Breadcrumb" data-sentry-source-file="[[...page]].tsx" />
                <Banner data-sentry-element="Banner" data-sentry-source-file="[[...page]].tsx" />
                <ConfirmUserDetails data-sentry-element="ConfirmUserDetails" data-sentry-source-file="[[...page]].tsx" />
                {PageComponent}
                <Debug page={page} data-sentry-element="Debug" data-sentry-source-file="[[...page]].tsx" />
            </InteractionsProvider>
        </Layout>;
}
export interface IPageInteractions {
  hasNav: boolean;
  hasTools: boolean;
  favorite?: {
    name: string;
    template: string;
    url: string;
  };
}

/**
 * Evaluate page and url, return information for InteractionsProvider
 * @param page Current IPage
 * @param url Current url
 * @returns IPageInteractions
 */
function getPageInteractions(page: IPage, url: string): IPageInteractions {
  if (url === "/") {
    return {
      hasNav: false,
      hasTools: false
    };
  }
  if (getContentType(page.types) === CONTENT_TYPE.LIST) {
    return {
      hasNav: true,
      hasTools: false
    };
  }
  return {
    hasNav: page.template?.hasNavigation ?? false,
    hasTools: getContentType(page.types) === CONTENT_TYPE.ARTICLE_DEFAULT ? false : page.template?.hasTools ?? false,
    favorite: {
      name: page.title,
      template: page.template?.name ?? "",
      url
    }
  };
}
export async function getStaticProps(ctx: GetStaticPropsContext) {
  const {
    page: path
  } = ctx.params as ParsedUrlQuery;
  const url = toPath(path);
  const revalidate = Number(process.env.REVALIDATE_STATIC_PROPS);

  // REVIEW: Do not fetch props for potential non-pages.
  if (!url || isNonPage(url)) {
    return {
      props: {},
      revalidate
    };
  }
  const page = await getPage(url);
  if (!page) {
    const redirect = await getRedirect(url);
    if (redirect) {
      return {
        redirect,
        revalidate
      };
    }
    return {
      notFound: true,
      revalidate
    };
  }
  if (!isPublished(page.published, page.unpublished)) {
    return {
      notFound: true,
      revalidate
    };
  }
  if (page.shortcut) {
    const destination = page.shortcut.replace(/(.+)\/$/, "$1");
    return {
      redirect: {
        destination
      },
      revalidate
    };
  }
  return {
    props: {
      page: page
    },
    revalidate
  };
}
export const getStaticPaths: GetStaticPaths = async () => {
  const sitelists: string[] = [];
  function slugToParam(slug: string) {
    return {
      params: {
        page: slug.split("/").filter(Boolean)
      }
    };
  }
  // NOTE: We can add high-traffic pages here, so they are generated at build-time
  return {
    fallback: "blocking",
    paths: sitelists.map(slugToParam)
  };
};

// IDEA: use /segment count to guess if sitelist or article
// IDEA: show same tools skeleton here while tools is loading
function PageSkeleton({
  page
}: {
  page: IPage;
}) {
  return <>
            <Breadcrumb page={page} data-sentry-element="Breadcrumb" data-sentry-source-file="[[...page]].tsx" />
            <Main placeholderLeft rightAside={<ToolsSkeleton />} data-sentry-element="Main" data-sentry-source-file="[[...page]].tsx">
                <article>
                    <Skeleton width="100%" height={32} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={80} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={80} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={300} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={40} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={40} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={300} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={40} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                    <Skeleton width="100%" height={300} data-sentry-element="Skeleton" data-sentry-source-file="[[...page]].tsx" />
                </article>
            </Main>
        </>;
}