import { NON_SUBSCRIBER_ROLES } from "auth/authorization"
import { IFrontpageCard } from "data/contentData/api/content"

export function getFrontPageCards(
    cards: IFrontpageCard[],
    authenticated: boolean,
    userRoles: string[]
) {
    if (!cards || !Array.isArray(cards)) {
        return []
    }

    const filteredCards = cards.filter(card => {
        // 1. Show if hasReadAccess is not an array or undefined
        if (!card.hasReadAccess || !Array.isArray(card.hasReadAccess)) {
            return true
        }

        // 2. Show if hasReadAccess is an empty array
        if (!card.hasReadAccess.length) {
            return true
        }

        // 3. If hasReadAccess includes NON_SUBSCRIBER_ROLES ...
        if (
            card.hasReadAccess.some(role => NON_SUBSCRIBER_ROLES.includes(role))
        ) {
            // 3.1 Show if user is not authenticated
            if (!authenticated) {
                return true
            }

            // 3.2 Show if user is authenticated and has no roles
            if (authenticated && !userRoles.length) {
                return true
            }
        }

        // 4. Show if user is subscriber and matches any of the roles in hasReadAccess
        return card.hasReadAccess.some(role => userRoles.includes(role))
    })

    return filteredCards
}
