import { isAuthorized, isOpenAccess } from "auth/authorization"
import { CONTENT_TYPE, getContentType } from "lib/get-content-type"

export type PaywallType =
    | "toolbox-subscriber"
    | "article-public-open-not-logged-in"
    | "article-public-open-no-sub"
    | "article-public-not-logged-in"
    | "article-public-no-sub"
    | "not-logged-in"
    | "no-sub"
    | "none"

export function getPaywallType(
    isLoading: boolean,
    pageTypes: string[],
    hasReadAccess: string[],
    authenticated: boolean,
    roles: string[]
): PaywallType {
    const contentType = getContentType(pageTypes)
    const articleIsOpenAccess = isOpenAccess(hasReadAccess)
    const userIsAuthorized = isAuthorized(roles, hasReadAccess)

    if (isLoading) {
        return "none"
    }

    if (contentType === CONTENT_TYPE.ARTICLE_PUBLIC) {
        if (articleIsOpenAccess) {
            if (process.env.NEXT_PUBLIC_ID === "medibas-se") {
                return "none"
            }

            if (!authenticated) {
                return "article-public-open-not-logged-in"
            }

            if (authenticated && Array.isArray(roles) && roles.length === 0) {
                return "article-public-open-no-sub"
            }
        } else {
            if (!authenticated) {
                return "article-public-not-logged-in"
            }

            if (authenticated && !userIsAuthorized) {
                return "article-public-no-sub"
            }
        }
    }

    if (articleIsOpenAccess) {
        return "none"
    }

    if (!authenticated) {
        return "not-logged-in"
    }

    if (
        hasReadAccess.includes("Handbook.Toolbox.Subscriber") &&
        !userIsAuthorized
    ) {
        return "toolbox-subscriber"
    }

    if (!userIsAuthorized) {
        return "no-sub"
    }

    return "none"
}
